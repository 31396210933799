/* eslint-disable @typescript-eslint/camelcase */
import { Strings } from 'core/types';

const strings: Strings = {
  en: {
    'bad request': 'bad request',
    'this page could not be found': 'this page could not be found',
    'method not allowed': 'method not allowed',
    gone: 'gone',
    'internal server error': 'internal server error',
    'an unexpected error has occurred': 'an unexpected error has occurred',
    'hot topics': 'hot topics',
    'more articles': 'more articles',
    'more than': '>',
    'official website': 'official website',
    'related topics': 'related topics',
    'social networks': 'social networks',
    'knowledia news': 'knowledia news',
    'latest news': 'latest news',
    'knowledia description': 'comprehensive up-to-date news coverage, aggregated from sources all over the world by Knowledia News.',
    'knowledia description about': 'comprehensive up-to-date news coverage about "{QUERY}", aggregated from sources all over the world by Knowledia News.',
    'very easy': 'very easy',
    easy: 'easy',
    'fairly easy': 'fairly easy',
    standard: 'standard',
    'fairly difficult': 'fairly difficult',
    difficult: 'difficult',
    'very difficult': 'very difficult',
    'about us': 'about us',
    all: 'all',
    article: 'article',
    articles: 'articles',
    business: 'business',
    'contact us': 'contact us',
    'contact us 😉': 'contact us 😉',
    'content language': 'content language',
    company: 'company',
    countries: 'countries',
    deprecated: 'deprecated',
    duration_read: '{DURATION} min read',
    editions: 'editions',
    entertainment: 'entertainment',
    environment: 'environment',
    error: 'error',
    'external links': 'external links',
    'fact-checking': 'fact-checking',
    health: 'health',
    'interface language': 'interface language',
    'loading statements': 'loading statements',
    'loading articles': 'loading articles',
    listen: 'listen',
    more: 'more',
    next: 'next',
    no_value: 'no value',
    politics: 'politics',
    preferred: 'preferred',
    previous: 'previous',
    privacy_policy: 'privacy policy',
    'read full article': 'read full article',
    result: 'result',
    results: 'results',
    statements: 'statements',
    science_and_technology: 'science & technology',
    search: 'search',
    seconds: 'seconds',
    some_value: 'some value',
    sports: 'sports',
    'suggested articles': 'suggested articles',
    topics: 'topics',
    trends: 'trends',
    types: 'types',
    'we are currently looking for funding!': 'we are currently looking for funding!',
    vision: 'vision',
    AU: 'Australia',
    BE: 'Belgium',
    CA: 'Canada',
    CH: 'Switzerland',
    FR: 'France',
    GB: 'United Kingdom',
    IL: 'Israel',
    IN: 'India',
    US: 'United States',
    ZA: 'South Africa',
    WORLD: 'World',
    aa: 'afar',
    ab: 'abkhazian',
    abe: 'western abenaki',
    abs: 'ambonese malay',
    ace: 'achinese',
    acf: 'acf',
    ady: 'adyghe',
    'ady-cyrl': 'adyghe (cyrillic script)',
    aeb: 'tunisian arabic',
    'aeb-arab': 'tunisian arabic (arabic script)',
    'aeb-latn': 'tunisian arabic (latin script)',
    af: 'afrikaans',
    agq: 'aghem',
    ak: 'akan',
    akl: 'akl',
    akz: 'alabama',
    aln: 'gheg albanian',
    als: 'alemannisch',
    gsw: 'swiss german',
    am: 'amharic',
    ami: 'amis',
    an: 'aragonese',
    ang: 'old english',
    anp: 'angika',
    aoc: 'aoc',
    ar: 'arabic',
    arc: 'aramaic',
    arn: 'mapuche',
    arq: 'algerian arabic',
    ary: 'moroccan arabic',
    arz: 'egyptian arabic',
    as: 'assamese',
    ase: 'american sign language',
    ast: 'asturian',
    atj: 'atikamekw',
    av: 'avaric',
    avk: 'kotava',
    awa: 'awadhi',
    ay: 'aymara',
    az: 'azerbaijani',
    azb: 'south azerbaijani',
    ba: 'bashkir',
    ban: 'balinese',
    bar: 'bavarian',
    'bat-smg': 'samogitian',
    sgs: 'samogitian',
    bbc: 'batak toba',
    'bbc-latn': 'batak toba (latin script)',
    bcc: 'southern balochi',
    bcl: 'central bikol',
    be: 'belarusian',
    'be-tarask': 'belarusian (taraškievica orthography)',
    'be-x-old': 'belarusian (taraškievica orthography)',
    bej: 'beja',
    bfi: 'bfi',
    bfq: 'badaga',
    bg: 'bulgarian',
    bgn: 'western balochi',
    bh: 'bhojpuri',
    bho: 'bhojpuri',
    bi: 'bislama',
    bjn: 'banjar',
    bm: 'bambara',
    bn: 'bangla',
    bnn: 'bunun',
    bo: 'tibetan',
    bpy: 'bishnupriya',
    bqi: 'bakhtiari',
    br: 'breton',
    brh: 'brahui',
    brx: 'bodo',
    bs: 'bosnian',
    bsk: 'bsk',
    bss: 'akoose',
    btm: 'batak mandailing',
    bto: 'iriga bicolano',
    bug: 'buginese',
    bxr: 'russia buriat',
    ca: 'catalan',
    'cbk-zam': 'chavacano',
    cdo: 'min dong chinese',
    ce: 'chechen',
    ceb: 'cebuano',
    ch: 'chamorro',
    chn: 'chinook jargon',
    cho: 'choctaw',
    chr: 'cherokee',
    chu: 'chu',
    chy: 'cheyenne',
    ckb: 'central kurdish',
    ckt: 'chukchi',
    cnr: 'montenegrin',
    co: 'corsican',
    cop: 'coptic',
    cps: 'capiznon',
    cr: 'cree',
    crh: 'crimean turkish',
    'crh-cyrl': 'crimean tatar (cyrillic script)',
    'crh-latn': 'crimean tatar (latin script)',
    crs: 'seselwa creole french',
    cs: 'czech',
    csb: 'kashubian',
    cu: 'church slavic',
    cv: 'chuvash',
    cy: 'welsh',
    da: 'danish',
    de: 'german',
    'de-at': 'austrian german',
    'de-ch': 'swiss high german',
    'de-formal': 'german (formal address)',
    din: 'dinka',
    diq: 'zazaki',
    dsb: 'lower sorbian',
    dtp: 'central dusun',
    dty: 'doteli',
    dv: 'divehi',
    dz: 'dzongkha',
    ee: 'ewe',
    egl: 'emilian',
    el: 'greek',
    eml: 'emiliano-romagnolo',
    en: 'english',
    'en-ca': 'canadian english',
    'en-gb': 'british english',
    'en-in': 'en-in',
    'en-us': 'american english',
    eo: 'esperanto',
    es: 'spanish',
    'es-419': 'latin american spanish',
    'es-formal': 'español (formal)&lrm;',
    'es-mx': 'mexican spanish',
    esu: 'central yupik',
    et: 'estonian',
    ett: 'etruscian',
    eu: 'basque',
    ext: 'extremaduran',
    eya: 'eyak',
    fa: 'persian',
    'fa-af': 'dari',
    ff: 'fulah',
    fi: 'finnish',
    fit: 'tornedalen finnish',
    'fiu-vro': 'võro',
    vro: 'võro',
    fj: 'fijian',
    fkv: 'kvensk',
    fo: 'faroese',
    fon: 'fon',
    fos: 'siraya',
    fr: 'french',
    'fr-be': 'fr-be',
    'fr-ca': 'canadian french',
    frc: 'cajun french',
    frm: 'middle french',
    fro: 'old french',
    frp: 'arpitan',
    frr: 'northern frisian',
    fuf: 'pular',
    fur: 'friulian',
    fy: 'western frisian',
    ga: 'irish',
    gaa: 'ga',
    gag: 'gagauz',
    gan: 'gan chinese',
    'gan-hans': 'gan (simplified)',
    'gan-hant': 'gan (traditional)',
    gcr: 'guianan creole',
    gd: 'scottish gaelic',
    gez: 'geez',
    gl: 'galician',
    glk: 'gilaki',
    gml: 'gml',
    gn: 'guarani',
    gom: 'goan konkani',
    'gom-deva': 'goan konkani (devanagari script)',
    'gom-latn': 'goan konkani (latin script)',
    gor: 'gorontalo',
    got: 'gothic',
    grc: 'ancient greek',
    gu: 'gujarati',
    gv: 'manx',
    ha: 'hausa',
    hai: 'haida',
    hak: 'hakka chinese',
    haw: 'hawaiian',
    he: 'hebrew',
    hi: 'hindi',
    hif: 'fiji hindi',
    'hif-latn': 'fiji hindi (latin script)',
    hil: 'hiligaynon',
    ho: 'hiri motu',
    hr: 'croatian',
    hrx: 'hunsrik',
    hsb: 'upper sorbian',
    ht: 'haitian creole',
    hu: 'hungarian',
    'hu-formal': 'magyar (formal)&lrm;',
    hy: 'armenian',
    hyw: 'western armenian',
    hz: 'herero',
    ia: 'interlingua',
    id: 'indonesian',
    ie: 'interlingue',
    ig: 'igbo',
    ii: 'sichuan yi',
    ik: 'inupiaq',
    'ike-cans': 'eastern canadian (aboriginal syllabics)',
    'ike-latn': 'eastern canadian (latin script)',
    ilo: 'iloko',
    ine: 'ine',
    inh: 'ingush',
    ins: 'ins',
    io: 'ido',
    is: 'icelandic',
    it: 'italian',
    iu: 'inuktitut',
    ja: 'japanese',
    jam: 'jamaican creole english',
    jbo: 'lojban',
    jut: 'jutish',
    jv: 'javanese',
    ka: 'georgian',
    kaa: 'kara-kalpak',
    kab: 'kabyle',
    kbd: 'kabardian',
    'kbd-cyrl': 'kabardian (cyrillic script)',
    kbp: 'kabiye',
    kea: 'kabuverdianu',
    kg: 'kongo',
    kha: 'khasi',
    khw: 'khowar',
    ki: 'kikuyu',
    kiu: 'kirmanjki',
    kj: 'kuanyama',
    kjh: 'khakas',
    kjp: 'eastern pwo',
    kk: 'kazakh',
    'kk-arab': 'kazakh (arabic script)',
    'kk-cn': 'kazakh (china)',
    'kk-cyrl': 'kazakh (cyrillic script)',
    'kk-kz': 'kazakh (kazakhstan)',
    'kk-latn': 'kazakh (latin script)',
    'kk-tr': 'kazakh (turkey)',
    kl: 'kalaallisut',
    km: 'khmer',
    kn: 'kannada',
    ko: 'korean',
    'ko-kp': 'korean (north korea)',
    koi: 'komi-permyak',
    koy: 'koyukon',
    kr: 'kanuri',
    krc: 'karachay-balkar',
    kri: 'krio',
    krj: 'kinaray-a',
    krl: 'karelian',
    krx: 'krx',
    ks: 'kashmiri',
    'ks-arab': 'kashmiri (arabic script)',
    'ks-deva': 'kashmiri (devanagari script)',
    ksh: 'colognian',
    ku: 'kurdish',
    'ku-arab': 'kurdish (arabic script)',
    'ku-latn': 'kurdish (latin script)',
    kum: 'kumyk',
    kv: 'komi',
    kw: 'cornish',
    ky: 'kyrgyz',
    la: 'latin',
    lad: 'ladino',
    lag: 'langi',
    'lat-vul': 'lat-vul',
    lb: 'luxembourgish',
    lbe: 'lak',
    lez: 'lezghian',
    lfn: 'lingua franca nova',
    lg: 'ganda',
    li: 'limburgish',
    lij: 'ligurian',
    liv: 'livonian',
    lki: 'laki',
    lkt: 'lakota',
    lld: 'ladin',
    lmo: 'lombard',
    ln: 'lingala',
    lo: 'lao',
    loz: 'lozi',
    lrc: 'northern luri',
    lt: 'lithuanian',
    ltg: 'latgalian',
    lus: 'mizo',
    luz: 'southern luri',
    lv: 'latvian',
    lzz: 'laz',
    mai: 'maithili',
    'map-bms': 'basa banyumasan',
    mdf: 'moksha',
    mfe: 'morisyen',
    mg: 'malagasy',
    mh: 'marshallese',
    mhr: 'eastern mari',
    mi: 'maori',
    min: 'minangkabau',
    mis: 'unsupported language',
    mk: 'macedonian',
    ml: 'malayalam',
    mn: 'mongolian',
    mnc: 'manchu',
    mni: 'manipuri',
    mnw: 'mon',
    mo: 'moldovan',
    moe: 'innu',
    mr: 'marathi',
    mrj: 'western mari',
    ms: 'malay',
    mt: 'maltese',
    mui: 'musi',
    mul: 'multiple languages',
    mus: 'muscogee',
    mwl: 'mirandese',
    mwv: 'mentawai',
    my: 'burmese',
    myv: 'erzya',
    mzn: 'mazanderani',
    na: 'nauru',
    nah: 'nāhuatl',
    nap: 'neapolitan',
    nb: 'norwegian bokmål',
    nds: 'low german',
    'nds-nl': 'low saxon',
    ne: 'nepali',
    new: 'newari',
    ng: 'ndonga',
    niu: 'niuean',
    nl: 'dutch',
    'nl-be': 'flemish',
    'nl-informal': 'nederlands (informeel)&lrm;',
    nn: 'norwegian nynorsk',
    no: 'norwegian',
    nod: 'northern thai',
    nog: 'nogai',
    non: 'old norse',
    nov: 'novial',
    nqo: 'n’ko',
    nr: 'south ndebele',
    nrm: 'fr-x-nrm',
    'fr-x-nrm': 'fr-x-nrm',
    nso: 'northern sotho',
    nui: 'nui',
    nv: 'navajo',
    nxm: 'numidian',
    ny: 'nyanja',
    nys: 'nyungar',
    oc: 'occitan',
    olo: 'livvi-karelian',
    om: 'oromo',
    ood: 'o\'odham',
    or: 'odia',
    os: 'ossetic',
    ota: 'ottoman turkish',
    otk: 'old turkish',
    pa: 'punjabi',
    pag: 'pangasinan',
    pam: 'pampanga',
    pap: 'papiamento',
    pcd: 'picard',
    pdc: 'pennsylvania german',
    pdt: 'plautdietsch',
    peo: 'old persian',
    pfl: 'palatine german',
    pi: 'pali',
    pih: 'norfuk / pitkern',
    pis: 'pis',
    pjt: 'pitjantjatjara',
    pko: 'pko',
    pl: 'polish',
    pms: 'piedmontese',
    pmy: 'pmy',
    pnb: 'western punjabi',
    pnt: 'pontic',
    ppu: 'papora-hoanya',
    prg: 'prussian',
    ps: 'pashto',
    pt: 'portuguese',
    'pt-br': 'brazilian portuguese',
    pyu: 'puyuma',
    qu: 'quechua',
    quc: 'kʼicheʼ',
    qug: 'chimborazo highland quichua',
    rcf: 'rcf',
    rgn: 'romagnol',
    rif: 'riffian',
    rm: 'romansh',
    rmy: 'vlax romani',
    rn: 'rundi',
    ro: 'romanian',
    'roa-tara': 'it-x-tara',
    'it-x-tara': 'it-x-tara',
    ru: 'russian',
    'ru-sib': 'ru-sib',
    rue: 'rusyn',
    rup: 'aromanian',
    ruq: 'megleno-romanian',
    'ruq-cyrl': 'megleno-romanian (cyrillic script)',
    'ruq-latn': 'megleno-romanian (latin script)',
    rw: 'kinyarwanda',
    rwr: 'marwari (india)',
    ryu: 'ryu',
    sa: 'sanskrit',
    sah: 'sakha',
    sat: 'santali',
    sc: 'sardinian',
    scn: 'sicilian',
    sco: 'scots',
    sd: 'sindhi',
    sdc: 'sassarese sardinian',
    sdh: 'southern kurdish',
    se: 'northern sami',
    sei: 'seri',
    ses: 'koyraboro senni',
    sg: 'sango',
    sh: 'serbo-croatian',
    shi: 'tachelhit',
    'shi-latn': 'tachelhit (latin script)',
    'shi-tfng': 'tachelhit (tifinagh script)',
    shn: 'shan',
    shy: 'shawiya',
    'shy-latn': 'shawiya (latin script)',
    si: 'sinhala',
    sid: 'sidamo',
    simple: 'en-simple',
    'en-x-simple': 'en-simple',
    'en-simple': 'en-simple',
    sjd: 'kildin sami',
    sje: 'pite sami',
    sjm: 'sjm',
    sju: 'ume sami',
    sk: 'slovak',
    skr: 'saraiki',
    'skr-arab': 'saraiki (arabic script)',
    sl: 'slovenian',
    sli: 'lower silesian',
    sm: 'samoan',
    sma: 'southern sami',
    smj: 'lule sami',
    smn: 'inari sami',
    sms: 'skolt sami',
    sn: 'shona',
    so: 'somali',
    sou: 'sou',
    sq: 'albanian',
    sr: 'serbian',
    'sr-ec': 'serbian (cyrillic script)',
    'sr-el': 'serbian (latin script)',
    srn: 'sranan tongo',
    srq: 'sirionó',
    ss: 'swati',
    ssf: 'thao',
    st: 'southern sotho',
    stq: 'saterland frisian',
    sty: 'cебертатар',
    su: 'sundanese',
    sv: 'swedish',
    sw: 'swahili',
    szl: 'silesian',
    szy: 'sakizaya',
    ta: 'tamil',
    tay: 'tayal',
    tcy: 'tulu',
    te: 'telugu',
    tet: 'tetum',
    tg: 'tajik',
    'tg-cyrl': 'tajik (cyrillic script)',
    'tg-latn': 'tajik (latin script)',
    th: 'thai',
    ti: 'tigrinya',
    tk: 'turkmen',
    tl: 'tagalog',
    tlh: 'klingon',
    tly: 'talysh',
    tn: 'tswana',
    to: 'tongan',
    tpi: 'tok pisin',
    tr: 'turkish',
    tru: 'turoyo',
    trv: 'taroko',
    ts: 'tsonga',
    tsg: 'tsg',
    tt: 'tatar',
    'tt-cyrl': 'tatar (cyrillic script)',
    'tt-latn': 'tatar (latin script)',
    tum: 'tumbuka',
    tvl: 'tuvalu',
    tw: 'twi',
    ty: 'tahitian',
    tyv: 'tuvinian',
    tzl: 'talossan',
    tzm: 'central atlas tamazight',
    udm: 'udmurt',
    ug: 'uyghur',
    'ug-arab': 'uyghur (arabic script)',
    'ug-latn': 'uyghur (latin script)',
    uk: 'ukrainian',
    umu: 'munsee',
    und: 'unknown language',
    ur: 'urdu',
    uun: 'pazeh',
    uz: 'uzbek',
    'uz-cyrl': 'uzbek (cyrillic script)',
    'uz-latn': 'uzbek (latin script)',
    uzs: 'uzs',
    ve: 'venda',
    vec: 'venetian',
    vep: 'veps',
    vi: 'vietnamese',
    vls: 'west flemish',
    vmf: 'main-franconian',
    vo: 'volapük',
    vot: 'votic',
    wa: 'walloon',
    wal: 'wolaytta',
    war: 'waray',
    wbl: 'wbl',
    wo: 'wolof',
    wuu: 'wu chinese',
    wym: 'wym',
    xal: 'kalmyk',
    xh: 'xhosa',
    xmf: 'mingrelian',
    xpu: 'punic',
    xsy: 'saisiyat',
    yai: 'yai',
    yap: 'yapese',
    yav: 'yangben',
    ydg: 'ydg',
    yi: 'yiddish',
    yo: 'yoruba',
    yrk: 'yrk',
    yrl: 'nheengatu',
    za: 'zhuang',
    zea: 'zeelandic',
    zgh: 'standard moroccan tamazight',
    zh: 'chinese',
    'zh-classical': 'literary chinese',
    lzh: 'literary chinese',
    'zh-cn': 'chinese (china)',
    'zh-hans': 'simplified chinese',
    'zh-hant': 'traditional chinese',
    'zh-hk': 'chinese (hong kong)',
    'zh-min-nan': 'min nan chinese',
    nan: 'min nan chinese',
    'zh-mo': 'chinese (macau)',
    'zh-my': 'chinese (malaysia)',
    'zh-sg': 'chinese (singapore)',
    'zh-tw': 'chinese (taiwan)',
    'zh-yue': 'cantonese',
    yue: 'cantonese',
    zu: 'zulu',
    zun: 'zuni',
  },
  fr: {
    'bad request': 'requête erronée',
    'this page could not be found': 'page introuvable',
    'method not allowed': 'non autorisé',
    gone: 'page supprimée',
    'internal server error': 'erreur interne',
    'an unexpected error has occurred': 'une erreur s\'est produite',
    'hot topics': 'sujets d\'actualité',
    'more articles': 'plus d\'articles',
    'more than': 'plus de',
    'official website': 'page officielle',
    'related topics': 'sujets connexes',
    'social networks': 'réseaux sociaux',
    'knowledia news': 'knowledia actualités',
    'latest news': 'dernières actualités',
    'knowledia description': 'informations complètes et à jour, compilées par Knowledia Actualités à partir de sources d\'actualités du monde entier.',
    'knowledia description about': 'informations complètes et à jour concernant "{QUERY}", compilées par Knowledia Actualités à partir de sources d\'actualités du monde entier.',
    'very easy': 'très facile',
    easy: 'facile',
    'fairly easy': 'assez facile',
    standard: 'standard',
    'fairly difficult': 'assez difficile',
    difficult: 'difficile',
    'very difficult': 'très difficile',
    all: 'tout',
    'about us': 'à propos',
    article: 'article',
    articles: 'articles',
    business: 'économie',
    'contact us': 'nous contacter',
    'contact us 😉': 'contactez-nous 😉',
    'content language': 'langue du contenu',
    company: 'société',
    countries: 'pays',
    deprecated: 'déprécié',
    duration_read: 'lecture {DURATION} min',
    editions: 'éditions',
    entertainment: 'divertissement',
    environment: 'environnement',
    error: 'erreur',
    'external links': 'liens externes',
    'fact-checking': 'vérification des faits',
    health: 'santé',
    language: 'langue',
    'interface language': 'langue de l\'interface',
    'loading statements': 'chargement des faits',
    'loading articles': 'chargement des articles',
    listen: 'écouter',
    more: 'plus',
    next: 'suivant',
    no_value: 'pas de valeur',
    politics: 'politique',
    preferred: 'préféré',
    previous: 'précédent',
    privacy_policy: 'politique de confidentialité',
    'read full article': 'lire tout l\'article',
    result: 'résultat',
    results: 'résultats',
    statements: 'faits',
    science_and_technology: 'science & technologie',
    search: 'rechercher',
    seconds: 'secondes',
    some_value: 'valeur non définie',
    sports: 'sport',
    'suggested articles': 'articles suggérés',
    topics: 'sujets',
    trends: 'tendance',
    types: 'types',
    vision: 'vision',
    'we are currently looking for funding!': 'nous recherchons des financements !',
    AU: 'Australie',
    BE: 'Belgique',
    CA: 'Canada',
    CH: 'Suisse',
    FR: 'France',
    GB: 'Royaume-Uni',
    IL: 'Israël',
    IN: 'Inde',
    US: 'États-Unis',
    ZA: 'Afrique du Sud',
    WORLD: 'Monde',
    aa: 'afar',
    ab: 'abkhaze',
    abe: 'abénaqui',
    abs: 'malais d\'ambon',
    ace: 'aceh',
    acf: 'créole saint-lucien',
    ady: 'adyguéen',
    'ady-cyrl': 'adyghe in cyrillic script',
    aeb: 'arabe tunisien',
    'aeb-arab': 'arabe tunisien en écriture arabe',
    'aeb-latn': 'arabe tunisien en écriture latine',
    af: 'afrikaans',
    agq: 'aghem',
    ak: 'akan',
    akl: 'aklanon',
    akz: 'alabama',
    aln: 'guègue',
    als: 'suisse allemand',
    gsw: 'alémanique',
    am: 'amharique',
    ami: 'amis',
    an: 'aragonais',
    ang: 'vieil anglais',
    anp: 'angika',
    aoc: 'pemon',
    ar: 'arabe',
    arc: 'araméen',
    arn: 'mapudungun',
    arq: 'arabe algérien',
    ary: 'arabe marocain',
    arz: 'arabe égyptien',
    as: 'assamais',
    ase: 'langue des signes américaine',
    ast: 'asturien',
    atj: 'atikamekw',
    av: 'avar',
    avk: 'kotava',
    awa: 'awadhi',
    ay: 'aymara',
    az: 'azéri',
    azb: 'azéri du sud',
    ba: 'bachkir',
    ban: 'balinais',
    bar: 'bavarois',
    'bat-smg': 'samogitien',
    sgs: 'samogitien',
    bbc: 'batak toba',
    'bbc-latn': 'batak toba in latin script',
    bcc: 'baloutche du sud',
    bcl: 'bikol central',
    be: 'biélorusse',
    'be-tarask': 'taraškievica',
    'be-x-old': 'taraškievica',
    bej: 'bedja',
    bfi: 'langue des signes britannique',
    bfq: 'badaga',
    bg: 'bulgare',
    bgn: 'baloutche de l\'ouest',
    bh: 'bhodjpouri',
    bho: 'bhodjpouri',
    bi: 'bichelamar',
    bjn: 'banjar',
    bm: 'bambara',
    bn: 'bengali',
    bnn: 'bunun',
    bo: 'tibétain',
    bpy: 'manipourî de bishnupriyay',
    bqi: 'bakhtiari',
    br: 'breton',
    brh: 'brahoui',
    brx: 'bodo',
    bs: 'bosnien',
    bsk: 'bourouchaski',
    bss: 'akoose',
    btm: 'batak mandailing',
    bto: 'bikol rinconada',
    bug: 'bouguinais',
    bxr: 'bouriate de russie',
    ca: 'catalan',
    'cbk-zam': 'chavacano',
    cdo: 'mindong',
    ce: 'tchétchène',
    ceb: 'cebuano',
    ch: 'chamorro',
    chn: 'chinook',
    cho: 'choctaw',
    chr: 'cherokee',
    chu: 'slavon liturgique',
    chy: 'cheyenne',
    ckb: 'sorani',
    ckt: 'tchouktche',
    cnr: 'monténégrin',
    co: 'corse',
    cop: 'copte',
    cps: 'capiznon',
    cr: 'cri',
    crh: 'tatar de crimée',
    'crh-cyrl': 'tatar de crimée en alphabet cyrillique',
    'crh-latn': 'tatar de crimée en alphabet latin',
    crs: 'créole seychellois',
    cs: 'tchèque',
    csb: 'cachoube',
    cu: 'vieux-slave',
    cv: 'tchouvache',
    cy: 'gallois',
    da: 'danois',
    de: 'allemand',
    'de-at': 'allemand autrichien',
    'de-ch': 'allemand suisse',
    'de-formal': 'formal address in german',
    din: 'dinka',
    diq: 'zazaki',
    dsb: 'bas-sorabe',
    dtp: 'central dusun language',
    dty: 'dotyali',
    dv: 'maldivien',
    dz: 'dzongkha',
    ee: 'ewe',
    egl: 'émilien',
    el: 'grec',
    eml: 'émilien-romagnol',
    en: 'anglais',
    'en-ca': 'anglais canadien',
    'en-gb': 'anglais britannique',
    'en-in': 'anglais indien',
    'en-us': 'anglais américain',
    eo: 'espéranto',
    es: 'espagnol',
    'es-419': 'espagnol d\'amérique',
    'es-formal': 'formal spanish',
    'es-mx': 'espagnol mexicain',
    esu: 'yupik de l\'alaska central',
    et: 'estonien',
    ett: 'étrusque',
    eu: 'basque',
    ext: 'estrémègne',
    eya: 'eyak',
    fa: 'persan',
    'fa-af': 'dari',
    ff: 'peul',
    fi: 'finnois',
    fit: 'meänkieli',
    'fiu-vro': 'võro',
    vro: 'võro',
    fj: 'fidjien',
    fkv: 'kvène',
    fo: 'féroïen',
    fon: 'fon-gbe',
    fos: 'siraya',
    fr: 'français',
    'fr-be': 'français de belgique',
    'fr-ca': 'français canadien',
    frc: 'français de louisiane',
    frm: 'moyen français',
    fro: 'ancien français',
    frp: 'francoprovençal',
    frr: 'frison septentrional',
    fuf: 'pular',
    fur: 'frioulan',
    fy: 'frison occidental',
    ga: 'irlandais',
    gaa: 'ga',
    gag: 'gagaouze',
    gan: 'gan',
    'gan-hans': 'simplified gan',
    'gan-hant': 'traditional gan',
    gcr: 'créole guyanais',
    gd: 'gaélique écossais',
    gez: 'ge\'ez',
    gl: 'galicien',
    glk: 'gilaki',
    gml: 'moyen bas allemand',
    gn: 'guarani',
    gom: 'konkani de goa',
    'gom-deva': 'goan konkani in devanagari script',
    'gom-latn': 'goan konkani in latin script',
    gor: 'gorontalo',
    got: 'gotique',
    grc: 'grec ancien',
    gu: 'gujarati',
    gv: 'mannois',
    ha: 'haoussa',
    hai: 'haïda',
    hak: 'hakka',
    haw: 'hawaïen',
    he: 'hébreu',
    hi: 'hindi',
    hif: 'hindi des fidji',
    'hif-latn': 'fiji hindi in latin script',
    hil: 'hiligaïnon',
    ho: 'hiri motu',
    hr: 'croate',
    hrx: 'riograndenser hunsrückisch',
    hsb: 'haut-sorabe',
    ht: 'créole haïtien',
    hu: 'hongrois',
    'hu-formal': 'formal hungarian',
    hy: 'arménien',
    hyw: 'arménien occidental',
    hz: 'héréro',
    ia: 'interlingua',
    id: 'indonésien',
    ie: 'occidental',
    ig: 'igbo',
    ii: 'nuosu',
    ik: 'inupiak',
    'ike-cans': 'eastern canadian in aboriginal syllabics',
    'ike-latn': 'eastern canadian in latin script',
    ilo: 'ilocano',
    ine: 'indo-européen commun',
    inh: 'ingouche',
    ins: 'langue des signes indo-pakistanaise',
    io: 'ido',
    is: 'islandais',
    it: 'italien',
    iu: 'inuktitut',
    ja: 'japonais',
    jam: 'créole jamaïcain',
    jbo: 'lojban',
    jut: 'jute',
    jv: 'javanais',
    ka: 'géorgien',
    kaa: 'karakalpak',
    kab: 'kabyle',
    kbd: 'kabarde',
    'kbd-cyrl': 'kabardian in cyrillic script',
    kbp: 'kabiyé',
    kea: 'créole du cap-vert',
    kg: 'kikongo',
    kha: 'khasi',
    khw: 'khowar',
    ki: 'kikuyu',
    kiu: 'kirmancki',
    kj: 'kuanyama',
    kjh: 'khakasse',
    kjp: 'pwo de l’est',
    kk: 'kazakh',
    'kk-arab': 'kazakh in arabic script',
    'kk-cn': 'kazakh in china',
    'kk-cyrl': 'kazakh in cyrillic script',
    'kk-kz': 'kazakh in kazakhstan',
    'kk-latn': 'kazakh in latin script',
    'kk-tr': 'kazakh in turkey',
    kl: 'groenlandais',
    km: 'khmer',
    kn: 'kannada',
    ko: 'coréen',
    'ko-kp': 'munhwaŏ',
    koi: 'komi-permiak',
    koy: 'koyukon',
    kr: 'kanouri',
    krc: 'karatchaï-balkar',
    kri: 'krio',
    krj: 'kinaray-a',
    krl: 'carélien',
    krx: 'karone',
    ks: 'cachemiri',
    'ks-arab': 'kashmiri in arabic script',
    'ks-deva': 'kashmiri in devanagari script',
    ksh: 'francique ripuaire',
    ku: 'kurde',
    'ku-arab': 'kurdish in arabic script',
    'ku-latn': 'kurdish in latin script',
    kum: 'koumyk',
    kv: 'komi',
    kw: 'cornique',
    ky: 'kirghize',
    la: 'latin',
    lad: 'judéo-espagnol',
    lag: 'langi',
    'lat-vul': 'latin vulgaire',
    lb: 'luxembourgeois',
    lbe: 'lak',
    lez: 'lezghien',
    lfn: 'lingua franca nova',
    lg: 'luganda',
    li: 'limbourgeois',
    lij: 'ligure',
    liv: 'live',
    lki: 'laki',
    lkt: 'lakota',
    lld: 'ladin',
    lmo: 'lombard',
    ln: 'lingala',
    lo: 'lao',
    loz: 'lozi',
    lrc: 'lori du nord',
    lt: 'lituanien',
    ltg: 'latgalien',
    lus: 'mizo',
    luz: 'luri du sud',
    lv: 'letton',
    lzz: 'laze',
    mai: 'maïthili',
    'map-bms': 'banyumasan',
    mdf: 'moksa',
    mfe: 'créole mauricien',
    mg: 'malgache',
    mh: 'marshallais',
    mhr: 'mari des prairies',
    mi: 'maori de nouvelle-zélande',
    min: 'minangkabau',
    mis: 'riksmål',
    mk: 'macédonien',
    ml: 'malayalam',
    mn: 'mongol',
    mnc: 'mandchou',
    mni: 'meitei',
    mnw: 'môn',
    mo: 'moldave',
    moe: 'innu-aimun',
    mr: 'marathi',
    mrj: 'mari des montagnes',
    ms: 'malais',
    mt: 'maltais',
    mui: 'musi',
    mul: 'multilingue',
    mus: 'creek',
    mwl: 'mirandais',
    mwv: 'mentawai',
    my: 'birman',
    myv: 'erzya',
    mzn: 'mazandarani',
    na: 'nauruan',
    nah: 'nahuatl',
    nap: 'napolitain',
    nb: 'bokmål',
    nds: 'bas allemand',
    'nds-nl': 'bas-saxon néerlandais',
    ne: 'népalais',
    new: 'newari',
    ng: 'ndonga',
    niu: 'niue',
    nl: 'néerlandais',
    'nl-be': 'néerlandais de belgique',
    'nl-informal': 'informal dutch',
    nn: 'nynorsk',
    no: 'norvégien',
    nod: 'thaï du nord',
    nog: 'nogaï',
    non: 'vieux norrois',
    nov: 'novial',
    nqo: 'n\'ko',
    nr: 'nrebele',
    nrm: 'normand',
    'fr-x-nrm': 'normand',
    nso: 'sotho du nord',
    nui: 'kombe language',
    nv: 'navajo',
    nxm: 'libyque',
    ny: 'chewa',
    nys: 'nyungan',
    oc: 'occitan',
    olo: 'olonetsien',
    om: 'oromo',
    ood: 'o\'odham',
    or: 'oriya',
    os: 'ossète',
    ota: 'turc ottoman',
    otk: 'vieux-turc',
    pa: 'pendjabi de l’est',
    pag: 'pangasinan',
    pam: 'pampangan',
    pap: 'papiamento',
    pcd: 'picard',
    pdc: 'allemand de pennsylvanie',
    pdt: 'bas allemand mennonite',
    peo: 'vieux-perse',
    pfl: 'palatin',
    pi: 'pali',
    pih: 'pitcairnais',
    pis: 'pijin',
    pjt: 'pitjantjatjara',
    pko: 'pökoot',
    pl: 'polonais',
    pms: 'piémontais',
    pmy: 'malais papou',
    pnb: 'pendjabi de l\'ouest',
    pnt: 'grec pontique',
    ppu: 'papora',
    prg: 'vieux-prussien',
    ps: 'pachto',
    pt: 'portugais',
    'pt-br': 'portugais brésilien',
    pyu: 'puyuma',
    qu: 'quechua',
    quc: 'quiché',
    qug: 'kichwa',
    rcf: 'créole réunionnais',
    rgn: 'romagnol',
    rif: 'rifain',
    rm: 'romanche',
    rmy: 'romani',
    rn: 'kirundi',
    ro: 'roumain',
    'roa-tara': 'tarentin',
    'it-x-tara': 'tarentin',
    ru: 'russe',
    'ru-sib': 'sibérien',
    rue: 'rusyn',
    rup: 'aroumain',
    ruq: 'mégléno-roumain',
    'ruq-cyrl': 'megleno-romanian in cyrillic script',
    'ruq-latn': 'megleno-romanian in latin script',
    rw: 'kinyarwanda',
    rwr: 'marvari (inde)',
    ryu: 'okinawanais',
    sa: 'sanskrit',
    sah: 'iakoute',
    sat: 'santâlî',
    sc: 'sarde',
    scn: 'sicilien',
    sco: 'scots',
    sd: 'sindhi',
    sdc: 'sassarais',
    sdh: 'kurde du sud',
    se: 'same du nord',
    sei: 'seri',
    ses: 'koyraboro senni',
    sg: 'sango',
    sh: 'serbo-croate',
    shi: 'chleuh',
    'shi-latn': 'tachelhit in latin script',
    'shi-tfng': 'tachelhit in tifinagh script',
    shn: 'shan',
    shy: 'chaoui',
    'shy-latn': 'shawiya in latin script',
    si: 'cingalais',
    sid: 'sidama',
    simple: 'anglais simple',
    'en-x-simple': 'anglais simple',
    'en-simple': 'anglais simple',
    sjd: 'same de kildin',
    sje: 'same de pite',
    sjm: 'mapun',
    sju: 'same d\'ume',
    sk: 'slovaque',
    skr: 'saraiki',
    'skr-arab': 'saraiki in arabic script',
    sl: 'slovène',
    sli: 'silésien',
    sm: 'samoan',
    sma: 'same du sud',
    smj: 'same de lule',
    smn: 'same d\'inari',
    sms: 'same skolt',
    sn: 'shona',
    so: 'somali',
    sou: 'thaï du sud',
    sq: 'albanais',
    sr: 'serbe',
    'sr-ec': 'serbian written in cyrillic',
    'sr-el': 'serbian written in latin script',
    srn: 'sranan',
    srq: 'sirionó',
    ss: 'swati',
    ssf: 'thao',
    st: 'sotho du sud',
    stq: 'frison saterlandais',
    sty: 'tatar de sibérie',
    su: 'sunda',
    sv: 'suédois',
    sw: 'swahili',
    szl: 'silésien',
    szy: 'sakizaya',
    ta: 'tamoul',
    tay: 'atayal',
    tcy: 'toulou',
    te: 'télougou',
    tet: 'tétoum',
    tg: 'tadjik',
    'tg-cyrl': 'tajik in cyrillic script',
    'tg-latn': 'tajik in latin script',
    th: 'thaï',
    ti: 'tigrigna',
    tk: 'turkmène',
    tl: 'tagalog',
    tlh: 'klingon',
    tly: 'talysh',
    tn: 'tswana',
    to: 'tongien',
    tpi: 'tok pisin',
    tr: 'turc',
    tru: 'turoyo',
    trv: 'seediq',
    ts: 'tsonga',
    tsg: 'tausug',
    tt: 'tatar',
    'tt-cyrl': 'tatar en alphabet cyrillique',
    'tt-latn': 'tatar en alphabet latin',
    tum: 'tumbuka',
    tvl: 'tuvaluan',
    tw: 'twi',
    ty: 'tahitien',
    tyv: 'touvain',
    tzl: 'talossan',
    tzm: 'tamazight du maroc central',
    udm: 'oudmourte',
    ug: 'ouïghour',
    'ug-arab': 'uyghur in arabic script',
    'ug-latn': 'uyghur in latin script',
    uk: 'ukrainien',
    umu: 'munsee',
    und: 'undetermined language',
    ur: 'ourdou',
    uun: 'pazeh',
    uz: 'ouzbek',
    'uz-cyrl': 'uzbek in cyrillic script',
    'uz-latn': 'uzbek in latin script',
    uzs: 'ouzbek du sud',
    ve: 'venda',
    vec: 'vénitien',
    vep: 'vepse',
    vi: 'vietnamien',
    vls: 'flamand',
    vmf: 'franconien du main',
    vo: 'volapük',
    vot: 'vote',
    wa: 'wallon',
    wal: 'wolaytta',
    war: 'waray-waray',
    wbl: 'wakhi',
    wo: 'wolof',
    wuu: 'wu',
    wym: 'wilamowicien',
    xal: 'kalmouk',
    xh: 'xhosa',
    xmf: 'mingrélien',
    xpu: 'punique',
    xsy: 'saisiyat',
    yai: 'yaghnobi',
    yap: 'yap',
    yav: 'yangben',
    ydg: 'yidgha',
    yi: 'yiddish',
    yo: 'yoruba',
    yrk: 'nénètse',
    yrl: 'nheengatu',
    za: 'zhuang',
    zea: 'zélandais',
    zgh: 'amazighe standard marocain',
    zh: 'langues chinoises',
    'zh-classical': 'chinois classique',
    lzh: 'chinois classique',
    'zh-cn': 'putonghua',
    'zh-hans': 'chinois simplifié',
    'zh-hant': 'chinois traditionnel',
    'zh-hk': 'hong kong cantonese',
    'zh-min-nan': 'minnan',
    nan: 'minnan',
    'zh-mo': 'chinese in macau',
    'zh-my': 'malaysian mandarin',
    'zh-sg': 'mandarin de singapour',
    'zh-tw': 'mandarin de taïwan',
    'zh-yue': 'dialecte cantonais',
    yue: 'dialecte cantonais',
    zu: 'zoulou',
    zun: 'zuni',
  },
};

export default strings;
