export const HISTOGRAM_HISTORY_DAYS = 180;
export const ENABLE_ADS = true;
export const ADS_PROVIDERS = new Set<string>(['adsense', 'publift']);
export const DEFAULT_ADS_PROVIDER = 'adsense';
export const BLACKLISTED_TOPICS = new Set<string>([
  'A2kk6',
  'Brpzp',
  'Brpzn',
  'Brpzm',
  'Brpzi',
  'Brpzk',
  '8D7um',
  'B0JTu',
  'BuZWV',
  'BfvUR',
]);
export const DISABLE_ARTICLE_IMAGES_COUNTRIES = new Set<string>([
  'FR', 'BE', 'CH',
]);
export const SHORT_ARTICLE_HOSTNAMES = new Set<string>([
  'www.news.com.au',
  'news.com.au',
  'www.sunshinecoastdaily.com.au',
  'citywire.com',
  'citywire.co.uk',
]);
