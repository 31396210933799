import { ParsedUrlQuery } from 'querystring';
import { LinkProps } from 'next/link';
import jmespath from 'jmespath';
import { isArray } from 'util';
import { NextPageContext } from 'next';
import strings from 'translations/strings';
import { defaultInterfaceLanguage } from 'translations/config';
import { DEFAULT_ADS_PROVIDER } from 'core/configs';
import _ from 'lodash';
import {
  languages, Language, countries, Country, Translations, Article, countryLanguages,
} from './types';

export const hasItem = (array: any[]): boolean => array && array.length > 0;

export const slugify = (s: string): string => {
  const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;\'';
  const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz-------';
  const p = new RegExp(a.split('').join('|'), 'g');

  return s.toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, '-and-') // Replace & with 'and'
    .replace(/[^\w-]+/g, '') // Remove all non-word characters
    .replace(/--+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text
};

export function capitalizeFirstLetter(s: string): string {
  return s.charAt(0).toUpperCase() + s.slice(1);
}

export function capitalize(s: string): string {
  const smallWords = /^(s|a|an|and|as|at|but|by|en|for|if|in|nor|of|on|or|per|the|to|t|v.?|vs.?|via|d|du|des|de|à|avec|chez|dans|dès|en|par|pour|sans|sous|sur|vers|au|le|la|les|l|ou|ne|pas|un|qui|une|unes|tout)$/i;
  const alphanumericPattern = /([a-zA-Z\u00C0-\u024F\u1E00-\u1EFF])/;
  const wordSeparators = /([ ':–—-])/;

  return s.split(wordSeparators)
    .map((current, index, array) => {
      if (
      /* Check for small words */
        current.search(smallWords) > -1
          /* Skip first and last word */
          && index !== 0
          && index !== array.length - 1
          /* Ignore title end and subtitle start */
          && array[index - 3] !== ':'
          && array[index + 1] !== ':'
          /* Ignore small words that start a hyphenated phrase */
          && (array[index + 1] !== '-'
            || (array[index - 1] === '-' && array[index + 1] === '-'))
      ) {
        return current.toLowerCase();
      }

      /* Ignore intentional capitalization */
      if (current.substr(1).search(/[A-Z]|\../) > -1) {
        return current;
      }

      /* Ignore URLs */
      if (array[index + 1] === ':' && array[index + 2] !== '') {
        return current;
      }

      /* Capitalize the first letter */
      return current.replace(alphanumericPattern, (match) => match.toUpperCase());
    })
    .join('');
}

export function updateParameters(url: string, parameters): string {
  const urlObject = new URL(url);
  for (const parameter of Object.keys(parameters)) {
    urlObject.searchParams.set(parameter, parameters[parameter]);
  }
  return urlObject.toString();
}

export function isUrl(s: string): boolean {
  return s.startsWith('http://') || s.startsWith('https://');
}

export function formatNumber(n: number, locale: string): string {
  return n.toLocaleString(locale);
}

export function translate(key: string, language: Language): string {
  return strings[language][key] || strings[defaultInterfaceLanguage][key] || key;
}

export function translateLabels(labels: Translations, language: Language): string {
  if (!labels) {
    return '';
  }

  if (language in labels) {
    return labels[language];
  }

  return labels.en || labels.fr;
}

export function parseFormatNumber(s: string, locale: string): {parsed: number; formatted: string} {
  try {
    const n = parseFloat(s);
    return { parsed: n, formatted: formatNumber(n, locale) };
  } catch (error) {
    return { parsed: null, formatted: s && s[0] === '+' ? s.slice(1) : s };
  }
}

export function formatQuantity(value: Record<string, any>, language: Language): string {
  const { amount, bounds, unit } = value;
  let parsedAmount: {parsed: number; formatted: string};
  let formattedAmount = amount;

  if (amount) {
    parsedAmount = parseFormatNumber(amount, language);
    formattedAmount = parsedAmount.formatted;
  }

  if (bounds) {
    const parsedLower = bounds.gte
      ? parseFormatNumber(bounds.gte, language) : { parsed: null, formatted: null };
    const parsedUpper = bounds.lte
      ? parseFormatNumber(bounds.lte, language) : { parsed: null, formatted: null };

    if ((parsedAmount.parsed && parsedLower.parsed && parsedUpper.parsed)
    && (parsedAmount.parsed - parsedLower.parsed === parsedUpper.parsed - parsedAmount.parsed)) {
      const formattedDelta = formatNumber(parsedAmount.parsed - parsedLower.parsed, language);
      formattedAmount = `${formattedAmount} ${formattedDelta !== '0' ? `± ${formattedDelta}` : ''}`;
    } else if ((parsedLower.formatted || parsedUpper.formatted)
    && (parsedLower.formatted !== formattedAmount || parsedUpper.formatted !== formattedAmount)) {
      formattedAmount = `${formattedAmount} [${parsedLower.formatted} - ${parsedUpper.formatted}]`;
    }
  }

  const formattedUnit = unit ? translateLabels(unit.labels, language) : '';
  return `${formattedAmount} ${formattedUnit}`;
}

export function cleanTime(time: string): string {
  // FIXME: waiting for https://github.com/Paikan/knowledia/issues/127
  return time.replace(' (ignored)', '');
}

export function formatTime(value: Record<string, any>, language: Language): string {
  if (!value) {
    return null;
  }

  // FIXME: waiting for https://github.com/Paikan/knowledia/issues/127
  const cleanedTime = cleanTime(value.time);

  try {
    const date = new Date(cleanedTime);
    if (Number.isNaN(date.getTime())) {
      return cleanedTime;
    }

    switch (value.precision) {
      case 9: // YEAR
        return date.toLocaleDateString(language, { year: 'numeric', timeZone: 'UTC' });
      case 10: // MONTH
        return date.toLocaleDateString(language, { month: 'long', year: 'numeric', timeZone: 'UTC' });
      case 11: // DAY
        return date.toLocaleDateString(language, {
          day: 'numeric', month: 'long', year: 'numeric', timeZone: 'UTC',
        });
      case 12: // HOUR
        return date.toLocaleDateString(language, {
          day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', timeZone: 'UTC',
        });
      case 13: // MINUTE
        return date.toLocaleDateString(language, {
          day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', minute: 'numeric', timeZone: 'UTC',
        });
      case 14: // SECOND
        return date.toLocaleDateString(language, {
          day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', timeZone: 'UTC',
        });
      default:
        return date.toLocaleDateString(language);
    }
  } catch (error) {
    return cleanedTime;
  }
}

export function truncate(s: string, n: number, useWordBoundary: boolean): string {
  if (!s) { return ''; }
  if (s.length <= n) { return s; }
  const subString = s.substr(0, n - 1);
  return `${useWordBoundary
    ? subString.substr(0, subString.lastIndexOf(' '))
    : subString}…`;
}

export function getFaviconURL(hostname: string): string {
  return `${process.env.KNOWLEDIA_FAVICONS_HOST}/v1/favicons/${hostname}`;
}

export function getAvatarURL(id: string, dimension = 0): string {
  return `${process.env.KNOWLEDIA_AVATARS_HOST}/v1/kb/avatars/${id}?dimension=${dimension}`;
}

export function get(data: Record<string, any>, path: string, defaultValue: any = null): any {
  const value = jmespath.search(data, path);
  if (value === undefined || value === null) {
    return defaultValue;
  }

  return value;
}

export function getFirst(
  data: Record<string, any>, paths: string[], defaultValue: any = null,
): any {
  for (const path of paths) {
    const value = get(data, path);
    if (value !== undefined && value !== null) {
      return value;
    }
  }
  return defaultValue;
}

export function getLanguagesPaths(
  templatePath: string,
  interfaceLanguage: Language,
): string[] {
  const result = [templatePath.replace('$language', interfaceLanguage)];
  for (const language of languages) {
    if (language !== interfaceLanguage) {
      result.push(templatePath.replace('$language', language));
    }
  }
  return result;
}

export function getValidStatementValues(statements: Record<string, any>): string[] {
  if (!statements) {
    return [];
  }
  return [...new Set<string>(statements.filter((statement) => statement.meta.rank !== 'deprecated').map((statement) => statement.value))];
}

export function getLastValue(parameter: string | string[]): string {
  if (Array.isArray(parameter)) {
    return parameter[parameter.length - 1];
  }
  return parameter;
}

export function isEmptyArray(parameter: any): boolean {
  return Array.isArray(parameter) && parameter.length === 0;
}

export function getAsArray(parameter: string | string[]): string[] {
  if (!parameter) {
    return [];
  }
  if (!Array.isArray(parameter)) {
    return [parameter];
  }
  return parameter;
}

export function isLanguage(tested: string): tested is Language {
  return languages.some((language) => language === tested);
}

export function isCountry(tested: string): tested is Country {
  return countries.some((country) => country === tested);
}

export function isServerSide(): boolean {
  return typeof window === 'undefined';
}

export function canUseWebP(): boolean {
  if (isServerSide()) {
    return false;
  }

  const elem = document.createElement('canvas');

  if (elem.getContext && elem.getContext('2d')) {
    return elem.toDataURL('image/webp').indexOf('data:image/webp') === 0;
  }

  return false;
}

export function isRetina(): boolean {
  if (isServerSide()) {
    return false;
  }

  if (window.devicePixelRatio > 1.25) {
    return true;
  }

  const mediaQuery = '(-webkit-min-device-pixel-ratio: 1.25), (min--moz-device-pixel-ratio: 1.25), (-o-min-device-pixel-ratio: 5/4), (min-resolution: 1.25dppx)';
  if (window.matchMedia && window.matchMedia(mediaQuery).matches) {
    return true;
  }

  return false;
}

export function getStickyParameters(parameters: ParsedUrlQuery): ParsedUrlQuery {
  return {
    country: isEmptyArray(parameters.country) ? '' : parameters.country,
    contentLanguage: isEmptyArray(parameters.contentLanguage) ? '' : parameters.contentLanguage,
    interfaceLanguage: parameters.interfaceLanguage,
    ...(parameters.adsProvider && getLastValue(parameters.adsProvider) !== DEFAULT_ADS_PROVIDER
      ? { adsProvider: parameters.adsProvider } : {}),
  };
}

export function removeFromArray(array: any[], item: any): void {
  const index = array.indexOf(item);
  if (index > -1) {
    array.splice(index, 1);
  }
}

export function getAsCountryArray(parameter: string[]): Country[] {
  if (!parameter) {
    return [];
  }

  let result = [];
  for (const value of parameter) {
    const tokens = value.split(',').map((s) => s.trim());
    result = result.concat(tokens);
  }

  return Array.from(new Set(result.filter(isCountry)));
}

export function getAsLanguageArray(parameter: string[]): Language[] {
  if (!parameter) {
    return [];
  }

  let result = [];
  for (const value of parameter) {
    const tokens = value.split(',').map((s) => s.trim());
    result = result.concat(tokens);
  }

  return Array.from(new Set(result.filter(isLanguage)));
}

export function sameSets(s1: Set<any>, s2: Set<any>): boolean {
  if (s1.size !== s2.size) return false;
  return [...s1].every((value) => s2.has(value));
}

export function sameValuesArrays(a1: Array<any>, a2: Array<any>): boolean {
  if ((!a1 || !a2) && a1 !== a2) {
    return false;
  }
  return sameSets(new Set(a1), new Set(a2));
}

export function hashString(s: string): number {
  let hash = 0;
  if (s.length === 0) return hash;
  for (let i = 0; i < s.length; i += 1) {
    const chr = s.charCodeAt(i);
    // eslint-disable-next-line no-bitwise
    hash = ((hash << 5) - hash) + chr;
    // eslint-disable-next-line no-bitwise
    hash |= 0;
  }
  return hash;
}

export function isChildElement(targetElement: Node, parentElement: Node): boolean {
  let currentElement = targetElement;

  do {
    if (currentElement === parentElement) {
      return true;
    }
    currentElement = currentElement.parentNode;
  } while (currentElement);

  return false;
}

function addUsedMetaQualifiersAndReferences(
  values: Record<string, any>[], properties: Set<string>,
): void {
  for (const value of values) {
    if (value.meta && value.meta.qualifiers) {
      for (const qualifier of Object.keys(value.meta.qualifiers)) {
        properties.add(qualifier);
      }
    }
    if (value.meta && hasItem(value.meta.references)) {
      for (const reference of value.meta.references) {
        for (const referenceProperty of Object.keys(reference)) {
          properties.add(referenceProperty);
        }
      }
    }
  }
}

export function getUsedProperties(topic: Record<string, any>): string[] {
  const properties = new Set<string>();
  for (const property of Object.keys(topic)) {
    properties.add(property);
    const value = topic[property];
    if (Array.isArray(value)) {
      addUsedMetaQualifiersAndReferences(value, properties);
    } else if (typeof value === 'object') {
      for (const key of Object.keys(value)) {
        addUsedMetaQualifiersAndReferences(value[key], properties);
      }
    }
  }

  return Array.from(properties);
}

const parameterToURLPath = (parameter: string | string[]): string => {
  if (!parameter) {
    return 'all';
  }

  if (isArray(parameter)) {
    if (parameter.length === 0) {
      return 'all';
    }
    return parameter.join(',');
  }

  return parameter;
};

export const fixOldQuery = (ctx: NextPageContext): void => {
  if (ctx.query.interfaceLanguage && ctx.query.contentLanguage === '') {
    ctx.query.contentLanguage = ctx.query.interfaceLanguage;
  }
  if (ctx.query.interfaceLanguage === 'fr' && !ctx.query.country) {
    ctx.query.country = 'FR';
  }
  if (ctx.query.interfaceLanguage === 'en' && !ctx.query.country) {
    ctx.query.country = 'US';
  }
};

export const getLinkProps = (
  query: ParsedUrlQuery,
  parameters: ParsedUrlQuery,
  hrefPathname: string,
): LinkProps => {
  const hrefQuery = getStickyParameters(query);
  for (const parameter of Object.keys(parameters)) {
    if (parameter !== 'rest') {
      hrefQuery[parameter] = isEmptyArray(parameters[parameter]) ? '' : parameters[parameter];
    }
  }

  const country = getAsCountryArray(getAsArray(hrefQuery.country));
  if (
    hrefQuery.contentLanguage === undefined
    && country.length === 1
    && countryLanguages[country[0]].length === 1) {
    [hrefQuery.contentLanguage] = countryLanguages[country[0]];
  }

  if (!hrefQuery.interfaceLanguage || sameValuesArrays(
    getAsArray(hrefQuery.contentLanguage), getAsArray(hrefQuery.interfaceLanguage),
  )) {
    delete hrefQuery.interfaceLanguage;
  }

  const asQuery = { ...hrefQuery };
  delete asQuery.country;
  delete asQuery.contentLanguage;
  let asPathname = hrefPathname;
  for (const parameter of Object.keys(asQuery)) {
    if (hrefPathname.indexOf(`[${parameter}]`) !== -1) {
      asPathname = asPathname.replace(`[${parameter}]`, asQuery[parameter] as string);
      delete asQuery[parameter];
    }
  }

  return {
    href: { pathname: hrefPathname, query: hrefQuery },
    as: {
      pathname: `/${parameterToURLPath(hrefQuery.country)}/${parameterToURLPath(hrefQuery.contentLanguage)}${asPathname}`,
      query: asQuery,
    },
  };
};

export const getArticleURLId = (article: Article): string => `${article.title ? `${slugify(truncate(article.title, 75, true))}-` : ''}${article.id}`;

export const getImageURL = (url: string, width?: number): string => {
  if (url.indexOf('Special:FilePath') !== -1) {
    const urlObject = new URL(url);
    if (width) {
      urlObject.searchParams.set('width', width.toString());
    }
    return urlObject.href;
  }

  return url;
};

export const getFileURL = (url: string): string => {
  if (url.indexOf('Special:FilePath') !== -1) {
    return url.replace('/Special:FilePath/', '/File:');
  }

  return url;
};

export const isObject = (value: any): boolean => (typeof value === 'object' && !Array.isArray(value) && value !== null);

export const deduplicate = (array: Array<any>): Array<any> => [...new Set(array)];

export const removePrefix = (s: string, prefix: string): string => {
  if (!prefix || !s) {
    return s;
  }

  return s.replace(prefix, '').trim();
};

export const getDaysAgoDate = (days: number): Date => {
  const date = new Date();
  date.setTime(date.getTime() - days * 24 * 60 * 60 * 1000);
  date.setUTCHours(0);
  date.setUTCMinutes(0);
  date.setUTCSeconds(0);
  date.setUTCMilliseconds(0);
  return date;
};

export const getHoursAgoDate = (hours: number): Date => {
  const date = new Date();
  date.setTime(date.getTime() - hours * 60 * 60 * 1000);
  date.setUTCMinutes(0);
  date.setUTCSeconds(0);
  date.setUTCMilliseconds(0);
  return date;
};

export const cleanValues = (object: Record<string, any>): void => _.omitBy(
  object, (v) => v === null || v === undefined,
);

export const getAdsProvider = (query: ParsedUrlQuery): string => {
  let provider = getLastValue(query.adsProvider) || DEFAULT_ADS_PROVIDER;
  if (provider !== 'adsense' && provider !== 'publift') {
    provider = DEFAULT_ADS_PROVIDER;
  }
  return provider;
};
