export const languages = ['en', 'fr'] as const;

export type Language = typeof languages[number];

export const languageNames = {
  en: 'english',
  fr: 'français',
};

export const countries = ['US', 'GB', 'CA', 'AU', 'ZA', 'IL', 'IN', 'FR', 'BE', 'CH'] as const;

export type Country = typeof countries[number];

export type Strings = {
  [key in Language]: {
    [key: string]: string;
  }
}

export const countryLanguages: {[key in Country]: Language[]} = {
  US: ['en'],
  GB: ['en'],
  CA: ['en', 'fr'],
  AU: ['en'],
  ZA: ['en'],
  IL: ['en'],
  IN: ['en'],
  FR: ['fr'],
  BE: ['fr'],
  CH: ['fr'],
};

export const categories = ['business', 'entertainment', 'environment', 'health', 'politics', 'sports', 'science_and_technology'] as const;

export type Category = typeof categories[number];

export type Translations = {
  [key in Language]: string;
}

export interface Audio {
  url: string;
}

export interface Author {
  name: string;
}

export interface Image {
  url: string;
  width?: number;
  height?: number;
}

export interface Video {
  url: string;
}

export interface Entity {
  id: string;
  type: string;
  labels: Translations;
  count: number;
}

export type Readability = {
  [key in string]: number;
}

export interface Article {
  article_creation_date?: Date;
  article_last_modification_date?: Date;
  article_publication_date?: Date;
  audios?: Audio[];
  authors?: Author[];
  body: string;
  breadcrumb: string[];
  creation_date: Date;
  description: string;
  predicted_language: string;
  entities: Entity[];
  hostname: string;
  id: string;
  images?: Image[];
  is_accessible_for_free: boolean;
  is_fact_checking: boolean;
  keywords: string[];
  labels: {
    category: string;
    country: string;
    language: string;
  };
  language: string;
  last_update_date: Date;
  read_time_seconds: number;
  readability: Readability;
  root_id: string;
  root_description: string;
  root_title: string;
  status: string;
  title: string;
  url: string;
  videos: Video[];
}

export interface HitsCount {
  value: number;
  relation: string;
}

export interface SignificantEntity {
  entity: {
    id: string;
    labels: Translations;
  };
  doc_count: number;
  bg_count: number;
  score: number;
}

export interface DateHistogramBucket {
  key_as_string: string;
  key: number;
  doc_count: number;
}

export type DateHistogramResponse = DateHistogramBucket[];

export interface ArticlesFetchQuery {
  id: string | string[];
  field?: string | string[];
}

export interface ArticlesSearchQuery {
  query?: string;
  likeDocument?: string | string[];
  cursor?: string;
  count?: number;
  field?: string | string[];
  category?: string | string[];
  country?: string | string[];
  language?: string | string[];
  entities?: string | string[];
  significantEntities?: number;
  dateHistogramPeriod?: string;
  isFactChecking?: boolean;
  sort?: string;
}

export interface ArticlesSearchResponse {
  articles?: Article[];
  significant_entities?: SignificantEntity[];
  date_histogram?: DateHistogramResponse;
  total: HitsCount;
  took: number;
  cursors: {previous: string; next: string};
}

export interface AutocompleteTopicsQuery {
  query?: string;
  language?: string | string[];
  count?: number;
  field?: string | string[];
}

export interface TopicsFetchQuery {
  id: string | string[];
  field?: string | string[];
  inversedField?: string | string[];
}

export interface InstagramFeedQuery {
  username?: string;
  tag?: string;
}

export interface InstagramFeedResponse {
  profile_pic_url: string;
}

export interface PropertiesQuery {
  id: string | string[];
  field?: string | string[];
}

export interface Property {
  inversable?: boolean;
  data_type?: string;
  labels?: Translations;
  descriptions?: Translations;
  formatter_url?: string[];
}

export interface PropertiesResponse {
  [key: string]: Property;
}

export interface TopicPropertiesQuery {
  topicId: string;
  topicField?: string[];
  topicInversedField?: string[];
  propertiesField?: string[];
}

export interface TopicPropertiesResponse {
  topic: Record<string, any>;
  properties: PropertiesResponse;
}

export interface GeoIPQuery {
  ip: string;
}

export interface GeoIPResponse {
  range: number[];
  country: string;
}
